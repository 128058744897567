import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import {
  FaRegEnvelope,
  FaLinkedinIn,
  FaTwitter,
  FaGithub,
  FaStackOverflow,
} from "react-icons/fa"

const mailToUrl = `mailto:eric.h.francis1+blog@gmail.com?Subject=Hello`
const linkedinUrl = `https://www.linkedin.com/in/erichfrancis/`
const twitterUrl = `https://twitter.com/EricFrancisImpl`
const githubUrl = `https://github.com/ericfrancis`
const stackOverFlowUrl = `https://stackoverflow.com/users/843542/eric-francis`

const ListLink = props => (
  <li style={{ display: `inline-block`, marginLeft: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const ListLinkExternal = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <a
      href={props.to}
      target="_blank"
      title={`Contact Eric Francis at ${props.title}`}
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  </li>
)

export default ({ children }) => (
  <div
    style={{
      margin: `auto`,
      maxWidth: `800px`,
      paddingRight: `2rem`,
      paddingLeft: `2rem`,
      paddingTop: `${rhythm(1.5)}`,
      display: `flex`,
      minHeight: `100vh`,
      flexDirection: `column`,
    }}
  >
    <header style={{ marginBottom: `${rhythm(0.25)}` }}>
      <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
        <h3
          style={{
            marginBottom: `${rhythm(2)}`,
            display: `inline`,
            fontStyle: `normal`,
          }}
        >
          Eric Francis
        </h3>
      </Link>
      <nav style={{ listStyle: `none`, float: `right` }}>
        <ListLink to="/">Home</ListLink>
        {/* <ListLink to="/about/">About</ListLink> */}
        <ListLink to="/contact/">Contact</ListLink>
      </nav>
    </header>
    <main style={{ flexGrow: 1 }}>{children}</main>

    <footer
      style={{
        // display: `flex`,
        paddingTop: `${rhythm(0.25)}`,
        marginTop: `${rhythm(0.25)}`,
        borderTop: `.5px solid`,
      }}
    >
      <div style={{ fontSize: `.75rem`, float: `left` }}> © Eric Francis</div>
      <ul style={{ listStyle: `none`, float: `right` }}>
        <ListLinkExternal to={twitterUrl} title="Twitter">
          <FaTwitter />
        </ListLinkExternal>
        <ListLinkExternal to={linkedinUrl} title="LinkedIn">
          <FaLinkedinIn />
        </ListLinkExternal>
        <ListLinkExternal to={githubUrl} title="GitHub">
          <FaGithub />
        </ListLinkExternal>
        <ListLinkExternal to={stackOverFlowUrl} title="Stack Overflow">
          <FaStackOverflow />
        </ListLinkExternal>
        <li style={{ display: `inline-block` }}>
          <a href={mailToUrl} target="_top" title="Email Eric Francis">
            <FaRegEnvelope />
          </a>
        </li>
      </ul>
    </footer>
  </div>
)
